<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row class="justify-content-between">
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Search..."
                @input="debounceSearch(meta.offset=0, getRequestedQuery)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :items="employeeList"
        :fields="tableColumns"
        :busy="isTableLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            class="action-menu"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal.invitation-modal
              @click="getUserData(data.item)"
            >
              <feather-icon :icon="'TrashIcon'" />
              <span class="align-middle ml-50">
                Delete Invitation
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(name)="data">
          {{ data.item.first_name || data.item.last_name ? `${data.item.first_name} ${data.item.last_name}` : 'N/A' }}
        </template>
      </b-table>
      <TablePagination
        :offset="meta.offset"
        :total="meta.total"
        :limit="meta.limit"
        :list-data="employeeList"
        @paginate="paginate"
      />
      <b-modal
        id="invitation-modal"
        ref="invitation-modal"
        ok-only
        ok-variant="danger"
        modal-class="modal-danger"
        centered
        title="Block User"
      >
        <b-card-text>
          Are you sure you want to delete invitation of this user ?
        </b-card-text>
        <template #modal-footer>
          <b-button
            variant="danger"
            @click="deleteInvitation()"
            :disabled="isActionLoading"
          >
            <b-spinner
              v-if="isActionLoading"
              small
            />
            Confirm
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import TablePagination from '@/components/common/TablePagination.vue'

export default {
  name: 'Invitations',
  components: {
    TablePagination,
  },
  mixins: [helper],
  data() {
    return {
      isTableLoading: false,
      searchQuery: '',
      statusQuery: null,
      employeeList: [],
      userData: {},
      userToBlock: {},
      b2eId: '',
      isActionLoading: false,
      showAllFields: false,
      status: [
        { label: 'Accepted', value: true },
        { label: 'Pending', value: false },
      ],
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'actions', label: '' },
        { key: 'name', label: 'Name', thClass: 'lg' },
        {
          key: 'email', label: 'Email', thClass: 'md', formatter: value => value || 'N/A',
        },
        {
          key: 'phone', label: 'Phone', thClass: 'md', formatter: value => value || 'N/A',
        },
        {
          key: 'designation', label: 'Designation', thClass: 'md', formatter: value => value || 'N/A',
        },
        {
          key: 'department.name', label: 'Department', thClass: 'md', formatter: value => value || 'N/A',
        },
        { key: 'invitation_from', label: 'Invited By' },
      ],
    }
  },
  computed: {
    filterQuery() {
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      return `${search}`
    },
  },
  mounted() {
    this.getInvitationList()
  },
  methods: {
    getInvitationList() {
      this.isTableLoading = true
      api.getData(`b2e_dashboard/invitation/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.employeeList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
      })
    },
    getRequestedQuery() {
      this.isTableLoading = true
      this.meta.offset = 0
      this.getInvitationList()
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getInvitationList()
    },
    getUserData(data) {
      this.userData = data
    },
    deleteInvitation() {
      this.isActionLoading = true
      api.deleteData(`/b2e_dashboard/invitation/${this.userData.invite_id}/`, true).then(response => {
        if (response.data.status) {
          this.getInvitationList()
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been processed successfully!')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isActionLoading = false
        this.$refs['invitation-modal'].hide()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
